
import React, { useEffect, useState } from "react";
import axios, { AxiosResponse } from "axios";
import { Link } from "react-router-dom";
import { ArrivalsInterface, DeparturesInterface, AirlinesInterface, AirportsInterface, ClaimsInterface, StatusesInterface } from "../interfaces";
import Clock from "./Clock";
import DateDisplay from "./DateDisplay";
import "../fids.css";
import { toLocalTime, formatTz } from '../timezoneUtils';

import { CONFIG } from '../config';



const formatDateToDay = (date: Date) => {
    const formatter = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    });
    return formatter.format(date);
};





// Define the Fids functional component

function Fids() {
    const [arrivals, setArrivals] = useState<ArrivalsInterface[]>([]);
    const [departures, setDepartures] = useState<DeparturesInterface[]>([]);
    const [airlines, setAirlines] = useState<AirlinesInterface[]>([]);
    const [airports, setAirports] = useState<AirportsInterface[]>([]);
    const [claims, setClaims] = useState<ClaimsInterface[]>([]);
    const [statuses, setStatuses] = useState<StatusesInterface[]>([]);


    const fetchData = () => {
        axios
            .get<ArrivalsInterface[]>("/api/arrivals")
            .then((response: AxiosResponse<ArrivalsInterface[]>) => {
                const transformedData = response.data.map((arrival) => ({
                    ...arrival,
                    arrival_time: arrival.arrival_time ? new Date(arrival.arrival_time) : null,
                    arrival_actual_time: arrival.arrival_actual_time ? new Date(arrival.arrival_actual_time) : null,
                }));

                // Here, you filter out the arrivals that are not visible or have been soft-deleted.
                const visibleArrivals = transformedData.filter(dep => dep.arrival_visible && !dep.arrival_soft_deleted);


                const today = new Date();
                today.setHours(0, 0, 0, 0); // Set the time to midnight
                const endOfToday = new Date();
                endOfToday.setHours(23, 59, 59, 999); // Set the time to end of the day

                // Filter arrivals for today and exclude flights from the future
                const todaysArrivals: ArrivalsInterface[] = visibleArrivals.filter((arrival) => {
                    if (!arrival.arrival_time) return false;
                    const arrivalTime = new Date(arrival.arrival_time);
                    return arrivalTime >= today && arrivalTime <= endOfToday;
                });

                const nextFlight = todaysArrivals.reduce<ArrivalsInterface | null>((next, arrival) => {
                    const arrivalTime = arrival.arrival_time ? new Date(arrival.arrival_time) : null;
                    if (!arrivalTime || arrivalTime <= endOfToday) return next;
                    const nextArrivalTime = next?.arrival_time ? new Date(next.arrival_time) : null;
                    return (!next || (nextArrivalTime && arrivalTime < nextArrivalTime))
                        ? arrival
                        : next;
                }, null);

                if (nextFlight) todaysArrivals.push(nextFlight);

                // Sort arrivals by arrival_time in ascending order
                todaysArrivals.sort((a, b) => {
                    const timeA = a.arrival_time ? a.arrival_time.getTime() : Number.MAX_VALUE;
                    const timeB = b.arrival_time ? b.arrival_time.getTime() : Number.MAX_VALUE;
                    return timeA - timeB;
                });




                setArrivals(todaysArrivals);

            })
            .catch((error: any) => {
                console.error("Error fetching arrivals:", error);
            });

        axios
            .get<DeparturesInterface[]>("/api/departures")
            .then((response: AxiosResponse<DeparturesInterface[]>) => {
                const transformedData = response.data.map((departure) => ({
                    ...departure,
                    departure_time: departure.departure_time ? new Date(departure.departure_time) : null,
                    departure_actual_time: departure.departure_actual_time ? new Date(departure.departure_actual_time) : null,
                }));

                // Here, you filter out the departures that are not visible or have been soft-deleted.
                const visibleDepartures = transformedData.filter(dep => dep.departure_visible && !dep.departure_soft_deleted);


                const today = new Date();
                today.setHours(0, 0, 0, 0); // Set the time to midnight
                const endOfToday = new Date();
                endOfToday.setHours(23, 59, 59, 999); // Set the time to end of the day

                // Filter departures for today and exclude flights from the future
                const todaysDepartures: DeparturesInterface[] = visibleDepartures.filter((departure) => {
                    if (!departure.departure_time) return false;
                    const departureTime = new Date(departure.departure_time);
                    return departureTime >= today && departureTime <= endOfToday;
                });

                const nextFlight = todaysDepartures.reduce<DeparturesInterface | null>((next, departure) => {
                    const departureTime = departure.departure_time ? new Date(departure.departure_time) : null;
                    if (!departureTime || departureTime <= endOfToday) return next;
                    const nextDepartureTime = next?.departure_time ? new Date(next.departure_time) : null;
                    return (!next || (nextDepartureTime && departureTime < nextDepartureTime))
                        ? departure
                        : next;
                }, null);

                if (nextFlight) todaysDepartures.push(nextFlight);

                // Sort departures by departure_time in ascending order
                todaysDepartures.sort((a, b) => {
                    const timeA = a.departure_time ? a.departure_time.getTime() : Number.MAX_VALUE;
                    const timeB = b.departure_time ? b.departure_time.getTime() : Number.MAX_VALUE;
                    return timeA - timeB;
                });




                setDepartures(todaysDepartures);

            })
            .catch((error: any) => {
                console.error("Error fetching departures:", error);
            });



    };




    useEffect(() => {
        // Create a meta element
        const meta = document.createElement('meta');
        meta.httpEquiv = "refresh";
        meta.content = "60"; // Refresh every 300 seconds (5 minutes)

        // Append the meta element to the head
        document.head.appendChild(meta);

        // Cleanup: remove the meta element when the component is unmounted
        return () => {
            document.head.removeChild(meta);
        };
    }, []);

    useEffect(() => {
        // Add the 'gradient-background' class to the body element when the component mounts
        document.body.classList.add('gradient-background');

        // Remove the 'gradient-background' class from the body element when the component unmounts
        return () => {
            document.body.classList.remove('gradient-background');
        };
    }, []);




    useEffect(() => {
        fetchData(); // Initial fetch

        const interval = setInterval(fetchData, 60000); // Fetch every 60 seconds

        return () => {
            clearInterval(interval);
        };
    }, []);



    useEffect(() => {


        // Fetch data for airlines, airports, claims, and statuses using useEffect

        axios
            .get<AirlinesInterface[]>("/api/airlines") // Fetch airlines data
            .then((response: AxiosResponse<AirlinesInterface[]>) => {
                setAirlines(response.data);
            })
            .catch((error: any) => {
                console.error("Error fetching airlines:", error);
            });

        axios
            .get<AirportsInterface[]>("/api/airports") // Fetch airports data
            .then((response: AxiosResponse<AirportsInterface[]>) => {
                setAirports(response.data);
            })
            .catch((error: any) => {
                console.error("Error fetching airports:", error);
            });

        axios
            .get<ClaimsInterface[]>("/api/claims") // Fetch claims data
            .then((response: AxiosResponse<ClaimsInterface[]>) => {
                setClaims(response.data);
            })
            .catch((error: any) => {
                console.error("Error fetching claims:", error);
            });

        axios
            .get<StatusesInterface[]>("/api/statuses") // Fetch statuses data
            .then((response: AxiosResponse<StatusesInterface[]>) => {
                setStatuses(response.data);
            })
            .catch((error: any) => {
                console.error("Error fetching statuses:", error);
            });





    }, []);


    // Define helper functions to retrieve specific data from arrays

    const getAirlineName = (airlineId: number | null) => {
        const airline = airlines.find((airline) => airline.airline_id === airlineId);
        return airline ? airline.airline_name : "";
    };

    const getAirlineLogo = (airlineId: number | null) => {
        const airline = airlines.find((airline) => airline.airline_id === airlineId);
        return airline ? airline.airline_logo_sm : undefined;
    };


    const getAirlineCode = (airlineId: number | null) => {
        if (airlineId === null) return "";
        const airline = airlines.find((airline) => airline.airline_id === airlineId);
        return airline ? airline.airline_code : "";
    };

    const getAirportScreenName = (airportId: number | null) => {
        if (airportId === null) return "";
        const airport = airports.find((airport) => airport.airport_id === airportId);
        return airport ? airport.airport_screen_name : "";
    };



    const getClaimName = (claimId: number | null) => {
        const claim = claims.find((claim) => claim.claim_id === claimId);
        return claim ? claim.claim_name : "";
    };


    const getStatusName = (statusId: number | null) => {
        if (statusId === null) {
            return "Unknown";
        }
        const status = statuses.find((status) => status.status_id === statusId);
        return status ? status.status_name : "Unknown";
    };


    // };





    // Return the JSX that renders the arrivals list table

    return (
        <div className="fondo h-full">
            {/* Full-width header */}
            <div className="grid grid-cols-3 p-4 clock">
                <div>
                    <h1 className="text-4xl font-semibold"><Clock /></h1>
                    <p className="font-medium text-xl text-zinc-400"> <DateDisplay /></p>
                </div>
                <div className="text-center">
                    {/* FIDS 2 */}
                </div>
                <div className="flex justify-end">
                    <img src={CONFIG.ASSETS.LOGO} alt="Logo Aeropuerto" className="h-14 " />
                </div>
            </div>



            <div className="pl-5 pr-5 pb-5 pt-3 bg-black m-4 rounded-md">


                {/* Arrivals Section */}
                <section >
                    <div className="flex items-center mb-2 align-bottom pb-5 pt-5">
                        <img src={CONFIG.ASSETS.ARRIVALS_ICON} alt="Ícono arribos" className="h-14 mr-2" />
                        <h2 className="text-4xl font-semibold ml-2">Arribos</h2>
                        <p className="aerolinea text-ssm ml-2">/ Chegadas / Arrivals</p>
                        {/* <div className="rectangle-text"><span className="font-light">Gate </span>1</div> */}
                    </div>

                    <table className="w-full">
                        <thead className="fids-th fids-head h-14">
                            <tr className="bg-transparent border-0 mb-3 ">
                                <th className="min-content-width"></th>
                                <th className="separate-logo"><p>Voo / Flight</p>
                                    <h2 >Vuelo</h2></th>
                                <th ><p>Horário / Scheduled</p>
                                    <h2 >Programado</h2></th>
                                <th><p>Origem / From</p>
                                    <h2 >Origen</h2></th>
                                <th ><p>Status / Remark</p>
                                    <h2 >Estado</h2></th>
                                <th ><p>Actual</p>
                                    <h2 >Estimado</h2></th>
                            </tr>
                        </thead>

                        {arrivals.map((arrival, index) => (
                            <tr
                                key={arrival.arrival_id}
                                className="fids-tr"
                            >


                                <td className="logo-aerolinea">

                                    {arrival.arrival_airline_id !== null && (
                                        <img src={getAirlineLogo(arrival.arrival_airline_id) || ''} alt={getAirlineName(arrival.arrival_airline_id) || ''} />

                                    )}


                                </td>


                                <td className="pt-1 separate-logo">
                                    <span className="font-normal text-gray-400">{getAirlineCode(arrival.arrival_airline_id) || ""}</span> {arrival.arrival_flight_number || ""}<br></br>
                                    {/* <p className="aerolinea">{getAirlineName(arrival.arrival_airline_id) || ""}</p> */}
                                </td>

                                <td className="pt-1">
                                    {arrival.arrival_time ? formatTz(arrival.arrival_time, 'HH:mm') : '--:--'}
                                </td>



                                <td className="ciudad pt-1">
                                    {getAirportScreenName(arrival.arrival_airport_id) || ""}
                                </td>
                                <td className="pt-1">     {getStatusName(arrival.arrival_status_id)}
                                </td>



                                <td className="pt-1">
                                    {arrival.arrival_actual_time ? formatTz(arrival.arrival_actual_time, 'HH:mm') : '--:--'}
                                </td>


                            </tr>

                        ))}
                    </table>

                </section>

                {/* <div className="m-20"></div> */}

            </div>

            <div className="m-3"></div>

            <div className="pl-5 pr-5 pb-5 pt-3 bg-black  m-4 rounded-md">


                {/* Departures Section */}
                <section >
                    <div className="flex items-center mb-2 align-bottom pb-5 pt-5">
                        <img src={CONFIG.ASSETS.DEPARTURES_ICON} alt="Ícono arribos" className="h-14 mr-2" />
                        <h2 className="text-4xl font-semibold ml-2">Partidas</h2> <p className="aerolinea text-ssm"> / Arrivals</p>
                    </div>
                    <table className="w-full">
                        <thead className="fids-th fids-head h-14">
                            <tr className="bg-transparent border-0 mb-3">
                                <th className="min-content-width"></th>
                                <th className="separate-logo"><p>Voo / Flight</p>
                                    <h2 >Vuelo</h2></th>
                                <th ><p>Horário / Scheduled</p>
                                    <h2 >Programado</h2></th>
                                <th><p>Destinação / Destination</p>
                                    <h2 >Destino</h2></th>
                                <th ><p>Status / Remark</p>
                                    <h2 >Estado</h2></th>
                                <th ><p>Actual</p>
                                    <h2 >Estimado</h2></th>
                            </tr>
                        </thead>

                        {departures.map((departure, index) => (
                            <tr
                                key={departure.departure_id}
                                className="fids-tr"
                            >


                                <td className="logo-aerolinea">

                                    {departure.departure_airline_id !== null && (
                                        <img src={getAirlineLogo(departure.departure_airline_id) || ''} alt={getAirlineName(departure.departure_airline_id) || ''} />

                                    )}


                                </td>


                                <td className="separate-logo pt-1">
                                    <span className="font-normal text-gray-400">{getAirlineCode(departure.departure_airline_id) || ""}</span> {departure.departure_flight_number || ""}<br></br>
                                    {/* <p className="aerolinea">{getAirlineName(departure.departure_airline_id) || ""}</p> */}
                                </td>

                                <td className="pt-1">
                                    {departure.departure_time ? formatTz(departure.departure_time, 'HH:mm') : '--:--'}
                                </td>



                                <td className="ciudad pt-1">
                                    {getAirportScreenName(departure.departure_airport_id) || ""}
                                </td>


                                <td className="pt-1">     {getStatusName(departure.departure_status_id)}
                                </td>

                                <td className="pt-1">
                                    {departure.departure_actual_time ? formatTz(departure.departure_actual_time, 'HH:mm') : '--:--'}
                                </td>


                            </tr>

                        ))}
                    </table>

                </section>
            </div>

            <p className="text-lg text-center text-gray-400 mt-10">{CONFIG.DISCLAIMER}</p>
        </div >
    );
}

export default Fids;
