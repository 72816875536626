const ENV = process.env;

export const CONFIG = {
    NODE_ENV: ENV.REACT_APP_NODE_ENV,
    EXPRESS_API_URL: ENV.REACT_APP_EXPRESS_API_URL,
    TIMEZONE: ENV.REACT_APP_TIMEZONE,
    HEADER_TEXT: ENV.REACT_APP_HEADER_TEXT,
    DISCLAIMER: ENV.REACT_APP_DISCLAIMER,
    ASSETS: {
        BASE_URL: ENV.REACT_APP_CUSTOM_ASSETS_BASE_URL,
        LOGO: `${ENV.REACT_APP_CUSTOM_ASSETS_BASE_URL}/${ENV.REACT_APP_LOGO_URL}`,
        DEPARTURES_ICON: `${ENV.REACT_APP_CUSTOM_ASSETS_BASE_URL}/${ENV.REACT_APP_DEPARTURES_ICON}`,
        ARRIVALS_ICON: `${ENV.REACT_APP_CUSTOM_ASSETS_BASE_URL}/${ENV.REACT_APP_ARRIVALS_ICON}`,
    }
};
