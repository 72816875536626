//Main.tsx
import React, { useContext } from 'react'; // <-- Make sure useContext is imported here
import { Link, BrowserRouter as Router, useLocation } from 'react-router-dom';
import Routes, { privateRoutePaths } from './Routes';
import { AuthProvider, AuthContext } from './contexts/AuthContext'; // <-- Import AuthContext here
import { NavLink } from 'react-router-dom';



import { TimezoneContext } from './useTimezone'; // make sure the path is correct
import * as TimezoneUtils from './timezoneUtils'; // make sure the path is correct


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons'

import { CONFIG } from './config';


function Header() {
  const location = useLocation();
  const authContext = useContext(AuthContext);

  // Check if the user is authenticated and if there's a logout function available
  const { isAuthenticated, logout, user } = authContext ?? {}; // Destructure with fallback if context is undefined

  const isPrivateRoute = privateRoutePaths.includes(location.pathname as typeof privateRoutePaths[number]);

  if (!isPrivateRoute || !isAuthenticated) return null;

  // Accessing environment variables
  // const logoUrl = process.env.REACT_APP_LOGO_URL;
  // const headerText = process.env.REACT_APP_HEADER_TEXT;





  return (
    <header className="bg-blue-600 p-4 flex items-center justify-between">
      {/* Logo and Text Section */}
      <div className="flex items-center flex-grow-0">
        <img src={CONFIG.ASSETS.LOGO} alt="logo" className="mr-2 w-40" />
        {/* Make sure to handle the case when the logo URL is not valid */}
        {/* <h1 className="text-2xl text-white">{headerText}</h1> */}
      </div>

      {/* This spacer will push the nav and logout to the right */}
      <div className="flex-grow"></div>

      {/* Navigation */}
      <nav className="self-center">
        <ul className="flex space-x-4">
          <li>
            <NavLink
              to="/crud/arribos"
              className="text-white hover:text-gray-300"
            >
              Arribos   –
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/crud/arribos-w"
              className="text-white hover:text-gray-300"
            >
              Arribos de esta semana   –
            </NavLink>
          </li>

          <li>
            <NavLink
              to="/crud/partidas"
              className="text-white hover:text-gray-300"
            >
              Partidas   –
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/crud/partidas-w"
              className="text-white hover:text-gray-300"
            >
              Partidas de esta semana   –
            </NavLink>
          </li>

          <li>
            <NavLink
              to="/"
              className="text-white hover:text-gray-300"
              target="_blank"
            >
              Ver FIDS
            </NavLink>
          </li>

        </ul>
      </nav>


      {/* This spacer will push the nav to the middle */}
      <div className="flex-grow"></div>

      {/* User Info and Logout Section */}
      <div className="flex items-center flex-grow-0">
        <span className="text-red mr-4">{user?.user_username}</span> {/* Display the logged-in user's name */}
        <button
          className="bg-blue-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center text-sm"
          onClick={() => {
            if (logout) {
              logout(); // Call the logout function from your AuthContext
            }
          }}
        >
          Cerrar sesión   <FontAwesomeIcon icon={faRightFromBracket} />
        </button>
      </div>
    </header >

  );
}


function Main() {
  return (
    <React.StrictMode>
      <AuthProvider>
        <TimezoneContext.Provider value={TimezoneUtils}>
          <Router>
            <Header />
            <Routes />
          </Router>
        </TimezoneContext.Provider>
      </AuthProvider>
    </React.StrictMode>
  );
}

export default Main;

