// src/components/crud-arrivals.tsx
import React, { useEffect, useState } from "react";
import axios, { AxiosResponse } from "axios";
import { Link } from "react-router-dom";
import { ArrivalsInterface, AirlinesInterface, AirportsInterface, ClaimsInterface, StatusesInterface } from "../interfaces";
import Modal from './Modal';
import AddArrivalForm from './ArrivalsAddForm';
import { useTimezone } from '../useTimezone';



import { format, parseISO } from 'date-fns';
import { es } from 'date-fns/locale'; // import the Spanish locale


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faPlane, faPlaneArrival } from '@fortawesome/free-solid-svg-icons';

import "../crud.css";


import { CONFIG } from '../config';





const CrudArrivals = () => {
  const { formatTz, toLocalTime, toUtc } = useTimezone();




  // State hooks
  const [arrivals, setArrivals] = useState<ArrivalsInterface[]>([]);
  const [editingArrival, setEditingArrival] = useState<ArrivalsInterface | null>(null);
  const [airlines, setAirlines] = useState<AirlinesInterface[]>([]);
  const [airports, setAirports] = useState<AirportsInterface[]>([]);
  const [claims, setClaims] = useState<ClaimsInterface[]>([]);
  const [statuses, setStatuses] = useState<StatusesInterface[]>([]);
  const [creatingNewRecord, setCreatingNewRecord] = useState(false);



  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };


  const formatDateForDisplay = (date: Date | null): string => {
    if (!date) return "--:--";

    // Format the date in the desired format and Spanish locale
    let formattedDate = format(date, "EEE. d MMM. - HH:mm 'hs.'", { locale: es });

    // Split the formatted date into parts
    let parts = formattedDate.split(' ');

    // Capitalize the first letter of the day and month
    parts[0] = capitalizeFirstLetter(parts[0]); // Day
    parts[2] = capitalizeFirstLetter(parts[2]); // Month

    // Rejoin the parts back into a single string
    formattedDate = parts.join(' ');

    return formattedDate;
  };


  const handleSoftDelete = (arrival: ArrivalsInterface) => {
    // Confirm with the user if they really want to delete the record
    if (!window.confirm('Confirma eliminación?')) {
      return;
    }

    // Set the arrival_soft_deleted field to true
    const updatedArrival = {
      ...arrival,
      arrival_soft_deleted: true,
    };

    // Make an API call to update the arrival
    axios.put(`/api/arrivals/${arrival.arrival_id}`, updatedArrival)
      .then(response => {
        // If the update was successful, refetch the arrivals list
        if (response.status === 200) {
          fetchArrivals();
        }
      })
      .catch(error => {
        console.error('There was an error deleting the record:', error);
      });
  };

  // Data fetching functions
  const fetchArrivals = () => {
    console.log("Fetching arrivals..."); // Log to confirm function is called

    axios.get<ArrivalsInterface[]>("/api/arrivals")
      .then((response: AxiosResponse<ArrivalsInterface[]>) => {
        console.log("Received arrivals:", response.data); // Log fetched data

        // Check if 'response.data' is valid and log if it's not
        if (!response.data || !Array.isArray(response.data)) {
          console.error("Invalid data received", response.data);
          return; // Exit the function if data is invalid
        }







        const transformedData = response.data.map((arrival) => {
          // Check each 'arrival' object's structure
          if (!arrival || typeof arrival !== 'object') {
            console.warn("Invalid arrival object encountered", arrival);
            return arrival; // Skip transformation for this item
          }

          return {
            ...arrival,
            arrival_time: arrival.arrival_time ? toLocalTime(new Date(arrival.arrival_time)) : null,
            arrival_actual_time: arrival.arrival_actual_time ? toLocalTime(new Date(arrival.arrival_actual_time)) : null,
          };
        });

        console.log("Transformed data:", transformedData); // Log transformed data

        // Here, you filter out the arrivals that have been soft-deleted.
        const activeArrivals = transformedData.filter(dep => !dep.arrival_soft_deleted);

        console.log("Active arrivals:", activeArrivals); // Log active arrivals

        const sortedData = activeArrivals.sort((a, b) => {
          if (a.arrival_time && b.arrival_time) {
            return b.arrival_time.getTime() - a.arrival_time.getTime();
          }
          return 0;
        });

        console.log("Sorted data:", sortedData); // Log sorted data

        setArrivals(sortedData); // Update state with the sorted data
      })
      .catch((error: any) => {
        console.error("Error fetching arrivals:", error);
      });
  };





  useEffect(() => {
    console.log("Component mounted. Fetching data...");

    // Initiating fetchArrivals separately as it has its own logic inside
    fetchArrivals();

    // All other data fetching is grouped
    Promise.all([
      axios.get<AirlinesInterface[]>("/api/airlines"),
      axios.get<AirportsInterface[]>("/api/airports"),
      axios.get<ClaimsInterface[]>("/api/claims"),
      axios.get<StatusesInterface[]>("/api/statuses")
    ]).then((responses) => {
      const [airlines, airports, claims, statuses] = responses;

      // Check and set airlines data
      if (airlines.data) {
        setAirlines(airlines.data);
      } else {
        console.error("No valid data for airlines:", airlines);
      }

      // Check and set airports data
      if (airports.data) {
        setAirports(airports.data);
      } else {
        console.error("No valid data for airports:", airports);
      }

      // Check and set claims data
      if (claims.data) {
        setClaims(claims.data);
      } else {
        console.error("No valid data for claims:", claims);
      }

      // Check and set statuses data
      if (statuses.data) {
        setStatuses(statuses.data);
      } else {
        console.error("No valid data for statuses:", statuses);
      }
    }).catch((error) => {
      // This block catches any error that happened during the requests.
      console.error("Error fetching data:", error);
    });

    // Empty dependency array means this useEffect runs once when the component mounts.
  }, []);


  // Helper functions to get names based on IDs
  const getAirlineName = (airlineId: number) => {
    const airline = airlines.find((airline) => airline.airline_id === airlineId);
    return airline ? airline.airline_name : "";
  };

  const getAirlineCode = (airlineId: number) => {
    const airline = airlines.find((airline) => airline.airline_id === airlineId);
    return airline ? airline.airline_code : "";
  };

  const getAirportName = (airportId: number) => {
    const airport = airports.find((airport) => airport.airport_id === airportId);
    return airport ? airport.airport_screen_name : "";
  };

  const getClaimName = (claimId: number) => {
    const claim = claims.find((claim) => claim.claim_id === claimId);
    return claim ? claim.claim_name : "";
  };

  const getStatusName = (statusId: number) => {
    const status = statuses.find((status) => status.status_id === statusId);
    return status ? status.status_name : "";
  };



  // Rendering the component's UI

  return (
    <div className="bg-white p-4 min-h-screen text-black text-sm">
      {/* Header and add button */}
      <div className="flex justify-between items-center mb-4">
        <img src={CONFIG.ASSETS.ARRIVALS_ICON} alt="Arrival Icon"></img>
        <h1 className="text-4xl font-semibold">Arribos</h1>
        <button onClick={() => setCreatingNewRecord(true)} className="text-m bg-gray-300 border-solid	border-2 border-slate-800 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
          <FontAwesomeIcon icon={faPlaneArrival} />  Agregar arribo
        </button>
      </div>

      <hr className="mb-10"></hr>

      {/* Table for showing arrivals */}
      <table className="table-container text-left">
        {/* Table headers */}
        <thead className="table-header">
          <tr>
            <th className="table-header-cell">ID</th>
            <th className="table-header-cell">Aerolínea</th>
            <th className="table-header-cell">Orígen</th>
            <th className="table-header-cell">Claim</th>
            <th className="table-header-cell">Estado</th>
            <th className="table-header-cell">Vuelo</th>
            <th className="table-header-cell">Programado</th>
            <th className="table-header-cell">Efectivo</th>
            <th className="table-header-cell "></th>
          </tr>
        </thead>
        <tbody>
          {arrivals.map((arrival, index) => (
            <tr key={arrival.arrival_id} className={`CRUD-table-row ${index % 2 === 0 ? "" : "bg-gray-100"}`}>
              <td className="table-cell ">{arrival.arrival_id}</td>
              <td className="table-cell ">{arrival.arrival_airline_id ? getAirlineName(arrival.arrival_airline_id) : ""}</td>
              <td className="table-cell ">{arrival.arrival_airport_id ? getAirportName(arrival.arrival_airport_id) : ""}</td>
              <td className="table-cell ">{arrival.arrival_claim_id ? getClaimName(arrival.arrival_claim_id) : ""}</td>
              <td className="table-cell">{arrival.arrival_status_id ? getStatusName(arrival.arrival_status_id) : ""}</td>
              <td className="table-cell whitespace-normal ">
                {arrival.arrival_airline_id ? getAirlineCode(arrival.arrival_airline_id) : ""} {arrival.arrival_flight_number}
              </td>
              {/* <td className="table-cell">{arrival.arrival_time ? arrival.arrival_time.toLocaleString() : ""}</td> */}
              <td className="table-cell">
                {formatDateForDisplay(arrival.arrival_time)} {/* Use the new formatting function */}

              </td>

              <td className="table-cell">            {formatDateForDisplay(arrival.arrival_actual_time)} {/* Use the new formatting function */}
              </td>
              <td className="table-cell">
                <div className="flex items-center"> {/* This div is a flex container for the buttons */}
                  <button
                    onClick={() => setEditingArrival(arrival)}
                    className="text-white bg-blue-500 px-2 py-1 rounded flex items-center"
                  >
                    <span className="ml-1">✏️  Editar</span>
                  </button>

                  {/* You might want to add some margin between the buttons for spacing */}
                  <button
                    onClick={() => handleSoftDelete(arrival)}
                    className="text-white bg-red-500 ml-2 px-2 py-1 rounded flex items-center"
                  >
                    <span className="ml-1">🗑️  Eliminar</span>
                  </button>
                </div>
              </td>

            </tr>
          ))}
        </tbody>
      </table>

      {/* Modals for editing and creating records */}
      <Modal isOpen={editingArrival !== null} onClose={() => setEditingArrival(null)}>
        <AddArrivalForm
          initialData={editingArrival}
          onSubmit={() => {
            fetchArrivals();
            setEditingArrival(null);
          }}
        />
      </Modal>

      <Modal isOpen={creatingNewRecord} onClose={() => setCreatingNewRecord(false)}>
        <AddArrivalForm
          initialData={null}
          onSubmit={() => {
            fetchArrivals();
            setCreatingNewRecord(false);
          }}
        />
      </Modal>

      {/* Navigation link */}
      <Link to="/" className="mt-4 text-white">
        Go Home
      </Link>
    </div>
  );
}

export default CrudArrivals;