import React, { useEffect, useState } from "react";
import { format } from "date-fns";

function Clock() {
    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    const formattedTime = format(currentTime, "HH:mm");

    return <div className="clock-container">{formattedTime}</div>;
}

export default Clock;
