// src/components/Modal.tsx
import React, { useEffect } from 'react';

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
    // Ref for the modal content
    const modalContentRef = React.useRef<HTMLDivElement>(null);

    // Close modal on click outside
    const handleClickOutside = (event: MouseEvent) => {
        if (modalContentRef.current && !modalContentRef.current.contains(event.target as Node)) {
            onClose();
        }
    };

    // Close modal on ESC press
    const handleEscapePress = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            onClose();
        }
    };

    useEffect(() => {
        // Only add the listeners when the modal is open
        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
            document.addEventListener('keydown', handleEscapePress);
        }

        // Cleanup - remove listeners
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleEscapePress);
        };
    }, [isOpen, onClose]); // Re-run when isOpen or onClose changes

    if (!isOpen) return null;

    return (
        <div className="modal-backdrop">
            <div className="modal-content" ref={modalContentRef}>
                <button className="boton-cerrar" onClick={onClose}>❌  Cerrar</button>
                {children}
            </div>
        </div>
    );
};

export default Modal;
