//src/components/Login.tsx

import React, { useState, useContext, useEffect } from 'react'; // Don't forget to import useEffect

import { AuthContext } from '../contexts/AuthContext'; // Adjust the import to your file structure
import { Navigate } from 'react-router-dom';


import { CONFIG } from '../config';



// const logoUrl = process.env.REACT_APP_LOGO_URL;


const Login: React.FC = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const authContext = useContext(AuthContext);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        try {

            const apiUrl = process.env.REACT_APP_EXPRESS_API_URL;
            const response = await fetch(`${apiUrl}/api/users/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password }),
                credentials: 'include',
            });

            if (response.ok) {
                const data = await response.json();

                localStorage.setItem('isAuthenticated', 'true');


                authContext?.setIsAuthenticated(true);

            } else {
                const errorData = await response.json();
                setError(errorData.error);
            }
        } catch (error) {
            setError('Network error: Failed to login.');
        }
    };
    const [shouldRedirect, setShouldRedirect] = useState(false);



    useEffect(() => {
        if (authContext?.isAuthenticated) {
            console.log('Authentication state changed', authContext?.isAuthenticated);
            setShouldRedirect(true);
        }
    }, [authContext?.isAuthenticated]);

    if (shouldRedirect) {
        return <Navigate to="/crud/arribos" replace />;
    }



    return (
        <div className="max-w-md mx-auto p-4 bg-black rounded shadow-lg">
            <img className="mt-10" src={CONFIG.ASSETS.LOGO} alt="Logo del aeropuerto" />
            <form onSubmit={handleSubmit} className="space-y-4 mt-20">
                <div>

                    {/* <label className="block text-gray-700">Username</label> */}
                    <input
                        type="text"
                        placeholder="Nombre de usuario"
                        className="block w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500 text-black"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </div>
                <div>
                    {/* <label className="block text-gray-700">Password</label> */}
                    <input
                        type="password"
                        placeholder="Contraseña"
                        className="block w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500 text-black"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                {error && (
                    <p className="text-red-500">{error}</p>
                )}
                <button
                    type="submit"
                    className="w-full py-2 mt-4 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none"
                >
                    Login
                </button>
            </form>
        </div>

    );
};

export default Login;
