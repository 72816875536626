import React, { useEffect, useState } from 'react';

const WeatherComponent = () => {
    const [temperature, setTemperature] = useState(null);
    const [condition, setCondition] = useState(null);

    useEffect(() => {
        const fetchWeather = async () => {
            const apiKey = 'e07877a7dc7aa3d6243ac27e393bc438';
            const city = 'Sauce Viejo'; // Change to your city
            const url = `https://api.openweathermap.org/data/3.0/onecall?lat={lat}&lon={lon}&exclude={part}&appid={e07877a7dc7aa3d6243ac27e393bc438}`;

            try {
                const response = await fetch(url);
                const data = await response.json();

                setTemperature(data.main.temp);
                setCondition(data.weather[0].main);
            } catch (error) {
                console.error("Error fetching weather data:", error);
            }
        };

        fetchWeather();
    }, []);

    return (
        <div>
            {temperature !== null && condition !== null ? (
                <>
                    <p>Temperature: {temperature} °C</p>
                    <p>Condition: {condition}</p>
                </>
            ) : (
                <p>Loading weather data...</p>
            )}
        </div>
    );
}

export default WeatherComponent;
