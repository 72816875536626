// src/components/crud-departures.tsx
import React, { useEffect, useState } from "react";
import axios, { AxiosResponse } from "axios";
import { Link } from "react-router-dom";
import { DeparturesInterface, AirlinesInterface, AirportsInterface, GatesInterface, StatusesInterface } from "../interfaces";
import Modal from './Modal';
import AddDepartureForm from './DeparturesAddForm';
import { useTimezone } from '../useTimezone';


import { format, parseISO, startOfWeek, endOfWeek, isWithinInterval } from 'date-fns';
import { es } from 'date-fns/locale'; // import the Spanish locale


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faPlane, faPlaneDeparture } from '@fortawesome/free-solid-svg-icons';

import "../crud.css";


import { CONFIG } from '../config';



const CrudDepartures = () => {
    const { formatTz, toLocalTime, toUtc } = useTimezone();




    // State hooks
    const [departures, setDepartures] = useState<DeparturesInterface[]>([]);
    const [editingDeparture, setEditingDeparture] = useState<DeparturesInterface | null>(null);
    const [airlines, setAirlines] = useState<AirlinesInterface[]>([]);
    const [airports, setAirports] = useState<AirportsInterface[]>([]);
    const [gates, setGates] = useState<GatesInterface[]>([]);
    const [statuses, setStatuses] = useState<StatusesInterface[]>([]);
    const [creatingNewRecord, setCreatingNewRecord] = useState(false);



    const capitalizeFirstLetter = (string: string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };


    const formatDateForDisplay = (date: Date | null): string => {
        if (!date) return "--:--";

        // Format the date in the desired format and Spanish locale
        let formattedDate = format(date, "EEE. d MMM. - HH:mm 'hs.'", { locale: es });

        // Split the formatted date into parts
        let parts = formattedDate.split(' ');

        // Capitalize the first letter of the day and month
        parts[0] = capitalizeFirstLetter(parts[0]); // Day
        parts[2] = capitalizeFirstLetter(parts[2]); // Month

        // Rejoin the parts back into a single string
        formattedDate = parts.join(' ');

        return formattedDate;
    };


    const handleSoftDelete = (departure: DeparturesInterface) => {
        // Confirm with the user if they really want to delete the record
        if (!window.confirm('Confirma eliminación?')) {
            return;
        }

        // Set the departure_soft_deleted field to true
        const updatedDeparture = {
            ...departure,
            departure_soft_deleted: true,
        };

        // Make an API call to update the departure
        axios.put(`/api/departures/${departure.departure_id}`, updatedDeparture)
            .then(response => {
                // If the update was successful, refetch the departures list
                if (response.status === 200) {
                    fetchDepartures();
                }
            })
            .catch(error => {
                console.error('There was an error deleting the record:', error);
            });
    };

    // Data fetching functions
    const fetchDepartures = () => {
        console.log("Fetching departures..."); // Log to confirm function is called

        axios.get<DeparturesInterface[]>("/api/departures")
            .then((response: AxiosResponse<DeparturesInterface[]>) => {
                console.log("Received departures:", response.data); // Log fetched data

                // Check if 'response.data' is valid and log if it's not
                if (!response.data || !Array.isArray(response.data)) {
                    console.error("Invalid data received", response.data);
                    return; // Exit the function if data is invalid
                }


                // Calculate the current week interval
                const currentDate = new Date(); // Current date
                const weekStart = startOfWeek(currentDate, { weekStartsOn: 1 }); // week starts on Monday
                const weekEnd = endOfWeek(currentDate, { weekStartsOn: 1 });




                const transformedData = response.data.map((departure) => {
                    // Check each 'departure' object's structure
                    if (!departure || typeof departure !== 'object') {
                        console.warn("Invalid departure object encountered", departure);
                        return departure; // Skip transformation for this item
                    }

                    return {
                        ...departure,
                        departure_time: departure.departure_time ? toLocalTime(new Date(departure.departure_time)) : null,
                        departure_actual_time: departure.departure_actual_time ? toLocalTime(new Date(departure.departure_actual_time)) : null,
                    };
                });



                console.log("Transformed data:", transformedData); // Log transformed data

                // Filter out the soft-deleted records first
                const activeDepartures = transformedData.filter(dep => !dep.departure_soft_deleted);

                // Then filter for the current week
                const currentWeekDepartures = activeDepartures.filter(dep => {
                    return dep.departure_time && isWithinInterval(dep.departure_time, { start: weekStart, end: weekEnd });
                });

                // Now sort the departures that are active and in the current week
                // const sortedDepartures = currentWeekDepartures.sort((a, b) => {
                //     if (a.departure_time && b.departure_time) {

                //         return a.departure_time.getTime() - b.departure_time.getTime();
                //     }
                //     return 0; // return 0 if there's no departure_time to compare
                // });

                // 'b - a' for descending order

                const sortedDepartures = currentWeekDepartures.sort((a, b) => {
                    if (a.departure_time && b.departure_time) {

                        return b.departure_time.getTime() - a.departure_time.getTime();
                    }
                    return 0; // return 0 if there's no departure_time to compare
                });



                // Set the state with the sorted departures that are active and in the current week
                setDepartures(sortedDepartures);




            })
            .catch((error: any) => {
                console.error("Error fetching departures:", error);
            });
    };





    useEffect(() => {
        console.log("Component mounted. Fetching data...");

        // Initiating fetchDepartures separately as it has its own logic inside
        fetchDepartures();

        // All other data fetching is grouped
        Promise.all([
            axios.get<AirlinesInterface[]>("/api/airlines"),
            axios.get<AirportsInterface[]>("/api/airports"),
            axios.get<GatesInterface[]>("/api/gates"),
            axios.get<StatusesInterface[]>("/api/statuses")
        ]).then((responses) => {
            const [airlines, airports, gates, statuses] = responses;

            // Check and set airlines data
            if (airlines.data) {
                setAirlines(airlines.data);
            } else {
                console.error("No valid data for airlines:", airlines);
            }

            // Check and set airports data
            if (airports.data) {
                setAirports(airports.data);
            } else {
                console.error("No valid data for airports:", airports);
            }

            // Check and set gates data
            if (gates.data) {
                setGates(gates.data);
            } else {
                console.error("No valid data for gates:", gates);
            }

            // Check and set statuses data
            if (statuses.data) {
                setStatuses(statuses.data);
            } else {
                console.error("No valid data for statuses:", statuses);
            }
        }).catch((error) => {
            // This block catches any error that happened during the requests.
            console.error("Error fetching data:", error);
        });

        // Empty dependency array means this useEffect runs once when the component mounts.
    }, []);


    // Helper functions to get names based on IDs
    const getAirlineName = (airlineId: number) => {
        const airline = airlines.find((airline) => airline.airline_id === airlineId);
        return airline ? airline.airline_name : "";
    };

    const getAirlineCode = (airlineId: number) => {
        const airline = airlines.find((airline) => airline.airline_id === airlineId);
        return airline ? airline.airline_code : "";
    };

    const getAirportName = (airportId: number) => {
        const airport = airports.find((airport) => airport.airport_id === airportId);
        return airport ? airport.airport_screen_name : "";
    };

    const getGateName = (gateId: number) => {
        const gate = gates.find((gate) => gate.gate_id === gateId);
        return gate ? gate.gate_name : "";
    };

    const getStatusName = (statusId: number) => {
        const status = statuses.find((status) => status.status_id === statusId);
        return status ? status.status_name : "";
    };



    // Rendering the component's UI

    return (
        <div className="bg-white p-4 min-h-screen text-black text-sm">
            {/* Header and add button */}
            <div className="flex justify-between items-center mb-4">
                <img src={CONFIG.ASSETS.DEPARTURES_ICON} alt="Departure Icon"></img>
                <h1 className="text-4xl font-semibold">Partidas de esta semana</h1>
                <button onClick={() => setCreatingNewRecord(true)} className="text-m bg-gray-300 border-solid	border-2 border-slate-800 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
                    <FontAwesomeIcon icon={faPlaneDeparture} />  Agregar partida
                </button>
            </div>

            <hr className="mb-10"></hr>

            {/* Table for showing departures */}
            <table className="table-container text-left">
                {/* Table headers */}
                <thead className="table-header">
                    <tr>
                        <th className="table-header-cell">ID</th>
                        <th className="table-header-cell">Aerolínea</th>
                        <th className="table-header-cell">Destino</th>
                        <th className="table-header-cell">Puerta</th>
                        <th className="table-header-cell">Estado</th>
                        <th className="table-header-cell">Vuelo</th>
                        <th className="table-header-cell">Programado</th>
                        <th className="table-header-cell">Efectivo</th>
                        <th className="table-header-cell "></th>
                    </tr>
                </thead>
                <tbody>
                    {departures.map((departure, index) => (
                        <tr key={departure.departure_id} className={`CRUD-table-row ${index % 2 === 0 ? "" : "bg-gray-100"}`}>
                            <td className="table-cell ">{departure.departure_id}</td>
                            <td className="table-cell ">{departure.departure_airline_id ? getAirlineName(departure.departure_airline_id) : ""}</td>
                            <td className="table-cell ">{departure.departure_airport_id ? getAirportName(departure.departure_airport_id) : ""}</td>
                            <td className="table-cell ">{departure.departure_gate_id ? getGateName(departure.departure_gate_id) : ""}</td>
                            <td className="table-cell">{departure.departure_status_id ? getStatusName(departure.departure_status_id) : ""}</td>
                            <td className="table-cell whitespace-normal ">
                                {departure.departure_airline_id ? getAirlineCode(departure.departure_airline_id) : ""} {departure.departure_flight_number}
                            </td>
                            {/* <td className="table-cell">{departure.departure_time ? departure.departure_time.toLocaleString() : ""}</td> */}
                            <td className="table-cell">
                                {formatDateForDisplay(departure.departure_time)} {/* Use the new formatting function */}

                            </td>

                            <td className="table-cell">            {formatDateForDisplay(departure.departure_actual_time)} {/* Use the new formatting function */}
                            </td>
                            <td className="table-cell">
                                <div className="flex items-center"> {/* This div is a flex container for the buttons */}
                                    <button
                                        onClick={() => setEditingDeparture(departure)}
                                        className="text-white bg-blue-500 px-2 py-1 rounded flex items-center"
                                    >
                                        <span className="ml-1">✏️  Editar</span>
                                    </button>

                                    {/* You might want to add some margin between the buttons for spacing */}
                                    <button
                                        onClick={() => handleSoftDelete(departure)}
                                        className="text-white bg-red-500 ml-2 px-2 py-1 rounded flex items-center"
                                    >
                                        <span className="ml-1">🗑️  Eliminar</span>
                                    </button>
                                </div>
                            </td>

                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Modals for editing and creating records */}
            <Modal isOpen={editingDeparture !== null} onClose={() => setEditingDeparture(null)}>
                <AddDepartureForm
                    initialData={editingDeparture}
                    onSubmit={() => {
                        fetchDepartures();
                        setEditingDeparture(null);
                    }}
                />
            </Modal>

            <Modal isOpen={creatingNewRecord} onClose={() => setCreatingNewRecord(false)}>
                <AddDepartureForm
                    initialData={null}
                    onSubmit={() => {
                        fetchDepartures();
                        setCreatingNewRecord(false);
                    }}
                />
            </Modal>

            {/* Navigation link */}
            <Link to="/" className="mt-4 text-white">
                Go Home
            </Link>
        </div>
    );
}

export default CrudDepartures;