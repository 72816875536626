//index.tsx

import React from 'react';
import ReactDOM from 'react-dom';
import 'tailwindcss/tailwind.css';
// import '/src/styles.css';
import './styles.css'
import Main from './Main';
import reportWebVitals from './reportWebVitals';



const root = document.getElementById('root') as HTMLElement;
ReactDOM.render(
  <React.StrictMode>
    <Main />
  </React.StrictMode>,
  root
);

reportWebVitals();
