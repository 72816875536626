// useTimezone.ts
import { createContext, useContext } from 'react';
import * as TimezoneUtils from './timezoneUtils';

// Create a context that will provide the functions to the components
export const TimezoneContext = createContext<typeof TimezoneUtils>(TimezoneUtils);

export const useTimezone = () => {
    return useContext(TimezoneContext);
};
