// timezoneUtils.ts
import { zonedTimeToUtc, utcToZonedTime, format } from 'date-fns-tz';

// Get the timezone from the environment variable
export const TIMEZONE = process.env.REACT_APP_TIMEZONE || 'UTC';

export const toLocalTime = (date: Date): Date => {
    return utcToZonedTime(date, TIMEZONE);
};

export const toUtc = (date: Date, formatStr: string): Date => {
    const localDate = format(date, formatStr); // format date to avoid issues with timezone offset
    return zonedTimeToUtc(localDate, TIMEZONE);
};

export const formatTz = (date: Date, dateFormat: string): string => {
    const zonedDate = utcToZonedTime(date, TIMEZONE);
    return format(zonedDate, dateFormat, { timeZone: TIMEZONE });
};
