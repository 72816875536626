// ArrivalsAddForm.tsx

import React, { useState, useEffect } from "react";
import axios from "axios";
import { format as formatDate } from 'date-fns'; // Importing format from 'date-fns'

import { AirlinesInterface, AirportsInterface, ArrivalsInterface, ClaimsInterface, StatusesInterface } from "../interfaces";
import { useTimezone } from '../useTimezone'; // Adjust path if necessary


import { CONFIG } from '../config';




// Define the prop types for the AddArrival component
interface ArrivalFormProps {
  initialData: ArrivalsInterface | null;
  onSubmit: () => void; // Callback to trigger after form submission
}

// Define the AddArrival functional component
const AddArrival: React.FC<ArrivalFormProps> = ({ initialData, onSubmit }) => {


  const isEditMode = initialData !== null;
  // New state variable to track whether "Demorado" is selected
  const [isDelayed, setIsDelayed] = useState(false);

  const { toLocalTime, toUtc, formatTz } = useTimezone();






  console.log("AddArrivalForm initialData:", initialData);


  // Adjusting the initial date formatting function
  const formatInitialDate = (date: Date | null | undefined) => {
    if (!date) return "";
    return formatTz(date, "yyyy-MM-dd'T'HH:mm");
  };










  // Initialize state variables to store data from API responses
  const [airlines, setAirlines] = useState<AirlinesInterface[]>([]);
  const [airports, setAirports] = useState<AirportsInterface[]>([]);
  const [claims, setClaims] = useState<ClaimsInterface[]>([]);
  const [statuses, setStatuses] = useState<StatusesInterface[]>([]);















  // Initialize state variable to store form input data
  const [arrivalData, setArrivalData] = useState({
    arrival_airline_id: initialData?.arrival_airline_id ?? "",
    arrival_airport_id: initialData?.arrival_airport_id ?? "",
    arrival_claim_id: initialData?.arrival_claim_id ?? "",
    arrival_status_id: initialData?.arrival_status_id ?? "",
    arrival_flight_number: initialData?.arrival_flight_number ?? "",
    arrival_time: formatInitialDate(initialData?.arrival_time),
    arrival_actual_time: formatInitialDate(initialData?.arrival_actual_time),
    arrival_visible: initialData?.arrival_visible ?? 1,
    arrival_soft_deleted: initialData?.arrival_soft_deleted ?? 0,
  });



  // Fetch data from APIs using useEffect on component mount

  useEffect(() => {
    axios.get<AirlinesInterface[]>("/api/airlines").then((response) => setAirlines(response.data));
    axios.get<AirportsInterface[]>("/api/airports").then((response) => setAirports(response.data));
    axios.get<ClaimsInterface[]>("/api/claims").then((response) => setClaims(response.data));
    axios.get<StatusesInterface[]>("/api/statuses").then((response) => {
      setStatuses(response.data);

      // After statuses are set, check if the current status is "Demorado"
      if (isEditMode && initialData) {
        const currentStatus = response.data.find(status => status.status_id === initialData.arrival_status_id);
        const currentIsDelayed = currentStatus?.status_name === "Demorado";

        // Set the isDelayed state based on the current status
        setIsDelayed(currentIsDelayed);
      }
    });
  }, []);


  // Define a handler for input changes
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;

    // Update the arrivalData state as before
    setArrivalData(prevData => ({
      ...prevData,
      [name]: value, // directly store the value; handle date formatting/conversion separately
    }));

    // If the status field changes, check if "Demorado" is selected
    if (name === "arrival_status_id") {
      const selectedStatus = statuses.find(status => status.status_id.toString() === value);
      const selectedIsDelayed = selectedStatus?.status_name === "Demorado";

      // If "Demorado" is not selected, we should clear the "Estimado" field
      if (!selectedIsDelayed) {
        setArrivalData(prevData => ({
          ...prevData,
          [name]: value, // store the new status
          arrival_actual_time: "", // clear the "Estimado" field
        }));
      } else {
        // If "Demorado" is selected, we don't want to clear the "Estimado" field
        setArrivalData(prevData => ({
          ...prevData,
          [name]: value, // store the new status
        }));
      }

      // Update the isDelayed state based on whether "Demorado" is selected
      setIsDelayed(selectedIsDelayed);
    } else {
      // For other input changes, keep the existing logic
      setArrivalData(prevData => ({
        ...prevData,
        [name]: value,
      }));
    }
  };









  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    // It's important to ensure that the date strings are in the correct format and timezone.
    // Since you're using a utility for timezone conversion, you should use it here to ensure consistency.
    // This step is crucial to avoid time-related issues due to different client timezones.

    // Prepare the data with proper checks and conversions
    const preparedData = {
      ...arrivalData, // spreading the existing arrivalData
      arrival_claim_id: arrivalData.arrival_claim_id || null, // Convert empty strings to null
      // Convert date strings to the appropriate format before sending. If they are not set, send null.
      // The toUtc function should handle the conversion to the appropriate timezone and format.
      arrival_time: arrivalData.arrival_time ? toUtc(new Date(arrivalData.arrival_time), "yyyy-MM-dd'T'HH:mm:ss.SSSXXX") : null,
      arrival_actual_time: arrivalData.arrival_actual_time ? toUtc(new Date(arrivalData.arrival_actual_time), "yyyy-MM-dd'T'HH:mm:ss.SSSXXX") : null,

    };

    // Now, you can use 'preparedData' to send your request to the server.
    // The rest of your submission logic will follow here, depending on whether you're adding or updating a record.
    // For example, you might have a POST request for new records or a PUT request for updating existing ones.

    if (initialData) {
      // Edit Mode: Use PUT to update the existing record
      axios.put(`/api/arrivals/${initialData.arrival_id}`, preparedData)
        .then(() => {
          onSubmit(); // Callback after successful submission
        })
        .catch((error) => {
          console.error("Error updating arrival:", error);
        });
    } else {
      // Add Mode: Use POST to create a new record
      axios.post("/api/arrivals", preparedData)
        .then(() => {
          // Handle the response here. For instance, you might want to clear the form or provide user feedback.
          onSubmit(); // Callback after successful submission
        })
        .catch((error) => {
          console.error("Error creating new arrival:", error);
        });
    }
  };



  // Find the currently selected status
  const currentStatus = statuses.find(
    (status) => status.status_id === arrivalData.arrival_status_id
  );

  // Check if the current status is "Demorado"
  // const isDelayed = currentStatus?.status_name === "Demorado";




  return (
    <div className="bg-gray-800 text-white p-6 rounded-lg">
      {/* Form UI elements */}
      <div style={{ display: "flex", alignItems: "center" }}>
        <img className="h-12"
          src={CONFIG.ASSETS.ARRIVALS_ICON}
          alt="Arribos"
          style={{
            // maxHeight: "20px",
            verticalAlign: "middle", // Vertically center the image
          }}
        />
        <span className="text-3xl font-bold ml-4">
          {isEditMode ? 'Editar arribo' : 'Agregar arribo'}
        </span>
      </div>

      <hr className="mt-3 mb-3"></hr>

      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="block">



          {/* Airline selection */}
          <p className="text-white mb-1">Aerolínea:</p>
          {airlines
            .filter((airline) => airline.airline_active === 1)
            .map((airline) => (
              <label
                key={airline.airline_id}
                className="block p-2 border border-gray-600 bg-gray-700 text-white cursor-pointer"
              >
                <input
                  type="radio"
                  name="arrival_airline_id"
                  value={airline.airline_id.toString()} // Ensure this is a string, as all input values are strings
                  checked={arrivalData.arrival_airline_id.toString() === airline.airline_id.toString()} // Compare as strings, and ensure this comparison is correct
                  onChange={handleInputChange}
                  className="mr-2"
                />
                {airline.airline_name}
              </label>
            ))}
        </div>





        {/* Airport selection */}

        {/* Airport Radio Buttons */}
        <div className="block">
          <p className="text-white mb-1">Destino:</p>
          {airports
            .filter((airport) => airport.airport_active === 1)
            .map((airport) => (
              <label
                key={airport.airport_id}
                className="block p-2 border border-gray-600 bg-gray-700 text-white cursor-pointer"
              >
                <input
                  type="radio"
                  name="arrival_airport_id"
                  value={airport.airport_id.toString()}
                  checked={arrivalData.arrival_airport_id.toString() === airport.airport_id.toString()}
                  onChange={handleInputChange}
                  className="mr-2"
                />
                {airport.airport_name}
              </label>
            ))}
        </div>




        {/* Status Radio Buttons */}
        <div className="block">
          <p className="text-white mb-1">Estado:</p>
          {statuses
            .filter((status) => status.status_type === "Arrival")
            .map((status) => (
              <label
                key={status.status_id}
                className="block p-2 border border-gray-600 bg-gray-700 text-white cursor-pointer"
              >
                <input
                  type="radio"
                  name="arrival_status_id"
                  value={status.status_id.toString()}
                  checked={arrivalData.arrival_status_id.toString() === status.status_id.toString()}
                  onChange={handleInputChange}
                  className="mr-2"
                />
                {status.status_name}
              </label>
            ))}
        </div>

        {/* Status Radio Buttons
        <div className="block">
          <p className="text-white mb-1">Estado:</p>
          {statuses
            .filter((status) => status.status_type === "Arrival")
            .map((status) => (
              <label
                key={status.status_id}
                className="block p-2 border border-gray-600 bg-gray-700 text-white cursor-pointer"
              >
                <input
                  type="radio"
                  name="arrival_status_id"
                  value={status.status_id.toString()}
                  checked={arrivalData.arrival_status_id.toString() === status.status_id.toString()}
                  onChange={handleInputChange}
                  className="mr-2"
                />
                {status.status_name}
              </label>
            ))}
        </div> */}



        {/* Arrival Time input */}
        <label>
          Programado:
          <input
            type="datetime-local"
            name="arrival_time"
            value={arrivalData.arrival_time} // directly use the value from arrivalData
            onChange={handleInputChange}
            className="block w-full mt-1 p-2 rounded border border-gray-600 bg-gray-700 text-white focus:border-blue-500 focus:ring focus:ring-blue-200"
          />
        </label>

        {/* Flight Number input */}

        <label>
          Nº de vuelo:
          <input
            type="number"
            name="arrival_flight_number"
            value={arrivalData.arrival_flight_number}
            onChange={handleInputChange}
            className="block w-full mt-1 p-2 rounded border border-gray-600 bg-gray-700 text-white focus:border-blue-500 focus:ring focus:ring-blue-200"
          />
        </label>

        {/* Actual Time input */}
        {/* <label>
          Efectivo:
          <input
            type="datetime-local"
            name="arrival_actual_time"
            value={arrivalData.arrival_actual_time} // directly use the value from arrivalData
            onChange={handleInputChange}
            className="block w-full mt-1 p-2 rounded border border-gray-600 bg-gray-700 text-white focus:border-blue-500 focus:ring focus:ring-blue-200"
          />
        </label> */}


        {/* Conditional rendering of the "Efectivo" input field */}
        {
          isDelayed && (
            <label>
              Efectivo:
              <input
                type="datetime-local"
                name="arrival_actual_time"
                value={arrivalData.arrival_actual_time}
                onChange={handleInputChange}
                className="block w-full mt-1 p-2 rounded border border-gray-600 bg-gray-700 text-white focus:border-blue-500 focus:ring focus:ring-blue-200"
              />
            </label>
          )
        }


        {/* Render other form elements here */}

        {/* Submit button */}

        <button type="submit" className="w-full h-12 bg-blue-500 hover:bg-blue-600 text-white rounded p-2">
          {isEditMode ? '💾 Guardar cambios' : '➕ Agregar arribo'}
        </button>
      </form>
    </div>


  );
};

export default AddArrival;
