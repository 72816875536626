// src/contexts/AuthContext.tsx
import React, { createContext, useState, ReactNode } from 'react';
import axios from 'axios';
import { UsersInterface } from "../interfaces";

interface IAuthContext {
    isAuthenticated: boolean;
    setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
    user: UsersInterface | null;  // now using UsersInterface
    setUser: React.Dispatch<React.SetStateAction<UsersInterface | null>>;
    logout: () => void;
}

export const AuthContext = createContext<IAuthContext | undefined>(undefined);

interface IAuthProviderProps {
    children: ReactNode;
}

export const AuthProvider: React.FC<IAuthProviderProps> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
        () => localStorage.getItem('isAuthenticated') === 'true'
    );

    // State for user data
    const [user, setUser] = useState<UsersInterface | null>(null); // Initialize appropriately, perhaps from localStorage

    // Logout function
    const logout = async () => {
        try {
            // Communicate with the backend to logout
            await axios.post('/api/users/logout');
            // Update local state and storage
            setIsAuthenticated(false);
            setUser(null); // Clear user data
            localStorage.removeItem('isAuthenticated');
        } catch (error) {
            console.error("Error during logout:", error);
            // Handle errors as appropriate
        }
    };

    // Make sure to include all the values required by IAuthContext here
    return (
        <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, user, setUser, logout }}>
            {children}
        </AuthContext.Provider>
    );
};
