import React, { useContext } from 'react';
import { useRoutes } from 'react-router-dom';
import { AuthContext } from './contexts/AuthContext';
import ArrivalsList from './components/ArrivalsList';
// import AirportsList from './components/AirportsList';
// import Arrivals from './components/Arrivals';
import FidsOld from './components/FidsOld';
import Fids from './components/Fids';
import Login from './components/Login';
import UsersList from './components/UsersList';
// import Llegadas from './components/..Llegadas';
import Weather from './components/Weather';
import ArribosCRUD from './components/crud-arrivals';
import ArribosCRUDw from './components/crud-arrivals-w';
import DeparturesCRUD from './components/crud-departures';
import DeparturesCRUDw from './components/crud-departures-w'
import { Navigate } from 'react-router-dom';


const privateRouteComponents = {
    // '/airports-list': AirportsList,
    // '/add-arrival': ArrivalsList,
    // '/arrivals-list': ArrivalsList,
    // '/users-list': UsersList,
    '/crud/arribos': ArribosCRUD,
    '/crud/arribos-w': ArribosCRUDw,
    '/crud/partidas': DeparturesCRUD,
    '/crud/partidas-w': DeparturesCRUDw,
};

const privateRoutePaths = Object.keys(privateRouteComponents) as Array<keyof typeof privateRouteComponents>;

function Routes() {
    const authContext = useContext(AuthContext);

    const publicRoutes = [


        { path: '/fids', element: <Fids /> },  // Add this line
        // { path: '/fids2', element: <Fids2 /> },  // Add this line

        { path: '/', element: <Navigate to="/fids" replace /> },
        // { path: '/arrivals', element: <Arrivals /> },
        { path: '/login', element: <Login /> },
        // { path: '/llegadas', element: <Llegadas /> },
        { path: '/weather', element: <Weather /> },
    ];

    const privateRoutes = privateRoutePaths.map(path => {
        const Component = privateRouteComponents[path];
        return {
            path,
            element: authContext?.isAuthenticated ? <Component /> : <Login />,
        };
    });

    const element = useRoutes([...publicRoutes, ...privateRoutes]);

    return element;
}

export { privateRoutePaths };
export default Routes;
