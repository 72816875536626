//DateDisplay.tsx

import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import es from 'date-fns/locale/es';

function DateDisplay() {
    const [currentDate, setCurrentDate] = useState(new Date());

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentDate(new Date());
        }, 1000 * 60 * 60); // Update every hour

        return () => {
            clearInterval(interval);
        };
    }, []);

    const formattedDate = format(currentDate, "EEEE dd 'de' MMMM 'de'  yyyy", { locale: es });
    const capitalizedFormattedDate = formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);

    return <div className="date-container">{capitalizedFormattedDate}</div>;
}

export default DateDisplay;
